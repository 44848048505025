import { type FC } from 'react';
import { Spinner } from 'flowbite-react';

const FullScreenSpinner: FC = () => (
  <div className="h-screen flex items-center justify-center">
    <div className="">
      <Spinner color="info" size="xl" aria-label="Info spinner" />
    </div>
  </div>
);

export default FullScreenSpinner;
